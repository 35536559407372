export const environment = {
	production: false,
	appInsightsKey: '88f4bc46-e6f3-4ad7-93fa-5588ad30e8ca',
	env: 'stage',
	defaultTenant: '1',
	authClientID: 'STG-HE-RA',
	authClientSecret: '6EA53A27-1378-11EE-9DDF-000D3A47AF91',
	inactiveTimeLimit: 3600,
	helioGameJavaScriptUrl: 'https://cdn-helioengine-stg.azureedge.net/launch-game.js'
};
